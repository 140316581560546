body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.MuiTableCell-root.MuiTableCell-body{
	border-bottom: 1px solid var(--five-color);
}
 
.ThinCell{
	padding-left: 5px !important;
	padding-right: 5px !important;
}
 
.CentreTable{
	width: 94% !important;
	margin: 3% !important;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.MuiTab-fullWidth.Mui-selected{
	background-color: var(--two-color);
	color: var(--five-color);
}
 
.DeadRow{
	background-color: var(--two-color) !important;
	color: var(--four-color) !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
}
 
.ThiccRightCell{
	padding-left: 5px !important;
	padding-right: 16px !important;
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader{
	background-color: var(--three-color);
	color: var(--one-color);
}

.MuiTableRow-root.MuiTableRow-head{
	background-color: var(--three-color);
	color: var(--one-color);
}

.MuiTableHead-root{
	background-color: var(--three-color);
	color: var(--one-color);
}

.MuiSwitch-thumb{
	background-color: var(--three-color) !important;
}

.MuiSwitch-track{
	background-color: var(--one-color) !important;
}

.MuiTableCell-root.MuiTableCell-body{
	background-color: var(--four-color);
	color: var(--one-color);
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined{
	color: var(--one-color);
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input{
	background-color: var(--four-color);
	color: var(--one-color);
	border-color: var(--one-color);
}

input{
	background-color: var(--four-color);
	color: var(--one-color);
	border-color: var(--one-color);
}

.MuiBackdrop-root.MuiBackdrop-invisible.MuiModal-backdrop{
	background-color: unset;
}

.MuiPopover-root.MuiMenu-root.MuiModal-root{
	background-color: unset;
}

.MuiOutlinedInput-notchedOutline{
	color: var(--one-color);
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined{
	color: var(--one-color);
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl{
	color: var(--one-color);
}

.MuiTabs-indicator{
	background-color: var(--one-color) !important;
}

.MuiLinearProgress-root.MuiLinearProgress-colorPrimary.MuiLinearProgress-determinate{
	background-color: var(--five-color);
}

.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate{
	background-color: var(--three-color);
}

.MuiLinearProgress-root.MuiLinearProgress-colorPrimary.MuiLinearProgress-indeterminate{
	background-color: var(--three-color);
}

.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Indeterminate{
	background-color: var(--four-color);
}

.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar2Indeterminate{
	background-color: var(--four-color);
}

.MuiPaper-root {
	color: var(--one-color);
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary{
	color: var(--one-color);
	background-color: var(--three-color);
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
	background-color: var(--four-color);
	color: var(--one-color);
}

.react-swipeable-view-container {
	background-color: var(--one-color);
	color: var(--one-color);
}

.MuiTableContainer-root {
	background-color: var(--four-color);
	color: var(--one-color);
}

.MuiBox-root {
	background-color: var(--five-color);
	color: var(--one-color);
}

caption {
	color: var(--one-color);
}

tr {
	text-align: left;
	background-color: var(--four-color);
	color: var(--one-color);
}

td {
	text-align: left;
	background-color: var(--four-color);
	color: var(--one-color);
}

th {
	text-align: left;
	background-color: var(--three-color);
	color: var(--one-color);
}

table {
	text-align: left;
	background-color: var(--three-color);
	color: var(--one-color);
}

.MuiButtonBase-root.MuiTab-root{
	color: var(--one-color);
	opacity: unset;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.MuiTab-fullWidth{
	background-color: var(--three-color);
	color: var(--one-color);
}

.MuiOutlinedInput-notchedOutline{
	border-color: var(--two-color) !important;
}

div {
	background-color: var(--five-color);
}

li {
	text-align: left;
	color: var(--one-color);
}

ul {
	text-align: left;
	color: var(--one-color);
}

h2 {
	text-align: left;
	color: var(--one-color);
}

h3 {
	text-align: center;
	color: var(--one-color);
}

h4 {
	text-align: center;
	color: var(--one-color);
}

.Snow {
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 0;
	overflow: hidden;
	height: 100vh;
}

.Snowflake {
	dispalay: inline-block;
	width: 0.1%;
	color: var(--one-color);
	opacity: 0;
	font-size: 120px;
	margin: 0;
	padding: 0;
	animation: fall 16s linear infinite;
}

.MuiTable-root {
	margin: 1;
	color: var(--one-color);
	padding: 1;
}

.MuiTabs-root {
	background-color: unset;
}

.MuiTabs-scroller.MuiTabs-fixed {
	background-color: unset;
}

.MuiTabs-flexContainer {
	background-color: unset;
}

.MuiPaper-root {
	margin: 1;
	padding: 1;
}

.circle {
	border-radius: 50%;
	background-color: deepskyblue;
	width: 100px;
	height: 100px;
	opacity: 0;
	animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
}

@keyframes scaleIn {
	from {
		transform: scale(.3, .3);
		opacity: .3;
	}

	to {
		transform: scale(1.5, 1.5);
		opacity: 0;
	}
}

body {
    --one-color: hsl(147, 100%, 10%);
    --two-color: hsl(147, 50%, 45%);
    --three-color: hsl(147, 40%, 50%);
    --four-color: hsl(147, 20%, 70%);
    --five-color: hsl(147, 10%, 90%);
}

body.dark {
    --one-color: hsl(147, 100%, 90%);
    --two-color: hsl(147, 50%, 25%);
    --three-color: hsl(147, 40%, 20%);
    --four-color: hsl(147, 20%, 10%);
    --five-color: hsl(147, 10%, 5%);
}

/*:root {
    --one-color:#477362;
    --two-color:#98C9A3;
    --three-color:#BFD8BD;
    --four-color:#DDE7C7;
    --five-color:#EDEEC9;
}*/